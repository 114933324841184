import Welcome from "./welcome-view";
import {connect} from "react-redux";
import {isLoggedIn} from "src/features/session";

const mapStateToProps = state => {
  return {
    loggedIn: isLoggedIn(state),
  };
};

export default connect(
  mapStateToProps,
)(Welcome);
