import React, {useEffect, useMemo} from "react"
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Box, Button, Collapse} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {LOGIN_SCHEMA} from "src/features/session/session-validation";
import {TextField} from "src/components/form";
import Alert from "@material-ui/lab/Alert";
import {navigate} from "gatsby";
import {useHasMounted} from "src/packages/has-mounted-hook";
import {useDispatch, useSelector} from "react-redux";
import {getLoginErrors, isLoggedIn, isLoggingIn, login} from "src/features/session";

export default function LoginForm({visible}) {
  const dispatch = useDispatch();

  const isSubmitting = useSelector(isLoggingIn);
  const formErrors = useSelector(getLoginErrors);
  const loggedIn = useSelector(isLoggedIn);

  const hasMounted = useHasMounted();

  const form = useForm({
    resolver: yupResolver(LOGIN_SCHEMA),
    shouldUnregister: false,
  });
  const {handleSubmit, setError, clearErrors, register, formState} = form;
  const {isSubmitSuccessful, errors} = formState;
  const hasErrors = errors && Object.keys(errors).length > 0;

  const submitAction = useMemo(() => event => {
    event.preventDefault();
    return handleSubmit(data => {
      dispatch(login(data))
    })();
  }, [handleSubmit, dispatch]);

  useEffect(() => {
    if (!formErrors) {
      clearErrors();
      return;
    }
    for (const [field, error] of Object.entries(formErrors)) {
      setError(field, {type: 'manual', message: error}, {shouldFocus: true});
    }
  }, [formErrors, clearErrors, setError]);

  useEffect(() => {
    if (isSubmitSuccessful && loggedIn && visible) {
      navigate('/dashboard/');
    }
  }, [loggedIn, isSubmitSuccessful]);

  if (!hasMounted) {
    return null;
  }

  if (loggedIn) {
    return null;
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={submitAction}>
        <Box mt={2} mb={2}>
          <TextField
            required={false}
            label="E-Mail-Adresse"
            name="username"
            register={register}
            errors={errors}
            variant="outlined"
            type="text"
            disabled={isSubmitting}
            autoComplete="username"
          />
        </Box>
        <Box mt={2} mb={2}>
          <TextField
            required={false}
            label="Passwort"
            name="password"
            register={register}
            errors={errors}
            variant="outlined"
            type="password"
            disabled={isSubmitting}
            autoComplete="current-password"
          />
        </Box>

        <Collapse in={hasErrors}>
          <Box mb={2}>
            <Alert severity="error" variant="filled" icon={hasErrors ? undefined : false}>
              {hasErrors ? (
                errors?.non_field_errors?.message || errors?.detail?.message || "Bitte geben Sie Ihre Zugangsdaten ein."
              ) : <>&nbsp;</>}
            </Alert>
          </Box>
        </Collapse>

        <Box>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            style={{width: '100%'}}
            size='large'
          >
            {isSubmitting ? (
              <>
                <CircularProgress size='1rem'/>&nbsp;Daten werden geprüft...
              </>
            ) : (
              "Login"
            )}
          </Button>
        </Box>
      </form>
    </FormProvider>
  )
}
