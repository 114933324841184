import * as yup from "yup";
import yupLocaleDe from 'src/packages/yup-locale-de';

yup.setLocale(yupLocaleDe);

export const LOGIN_SCHEMA = yup.object().shape({
  username: yup.string().trim()
    .required(''),
  password: yup.string().trim()
    .required(''),
});
