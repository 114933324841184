import React from 'react';
import _ from 'lodash';

export default function Price({value, currency = "€"}) {
  if (value === undefined) {
    return '?';
  }
  if (_.isString(value)) {
    value = parseFloat(value);
  }
  if (currency === 'EUR') {
    currency = '€';
  }
  return (
    <span style={{whiteSpace: 'nowrap'}}>
      {
        value.toFixed(2)
          .replace('.', ',')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      } {currency}
    </span>
  );
}
