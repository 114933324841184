import Dashboard from "./dashboard-view";
import {connect} from "react-redux";
import {isLoggedIn} from "src/features/session";
import {getProfile, performProfileUpdate} from "src/features/participation";
import {getProgress} from "src/features/ui/progress";

const mapStateToProps = state => {
  const {status, documents, price_net, vat, price_gross, organization} = getProfile(state);

  const {inProgress: updatingParticipation, error, formErrors} = getProgress('profileUpdate', 'participation')(state);

  return {
    loggedIn: isLoggedIn(state),

    loaded: (status !== undefined),

    price_net,
    vat,
    price_gross,
    organization,

    willParticipate: (status === 'bezahlt' || status === 'nimmt_teil'),
    wontParticipate: (status === 'nimmt_nicht_teil'),
    hasPaid: (status === 'bezahlt'),

    updatingParticipation,
    participationUpdateError: !error ? null : (
      formErrors?.detail?.join(' ') ||
      formErrors?.non_field_errors?.join(' ') ||
      formErrors?.status?.join(' ') ||
      "Ihre Entscheidung konnte nicht gespeichert werden. Bitte überprüfen Sie Ihre Netzwerkverbindung."
    ),

    showDocuments: (documents?.length > 0 || status === 'nimmt_teil'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    takePart: () => dispatch(performProfileUpdate({id: 'participation', profile: {status: 'nimmt_teil'}})),
    doNotTakePart: () => dispatch(performProfileUpdate({id: 'participation', profile: {status: 'nimmt_nicht_teil'}})),
    revertDecision: () => dispatch(performProfileUpdate({id: 'participation', profile: {status: 'offen'}})),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);
