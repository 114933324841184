import React, {useEffect, useState} from "react";
import {
  Box,
  Button,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Paper,
  Typography
} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import Price from 'src/packages/price-view';

import {WelcomeDashboardLayout} from "src/components/layout";
import {navigate} from "gatsby";
import {ContactDetails, Downloads, Participation, ProjectTimeline} from "./components";
import {useSelector} from "react-redux";
import {getProfile} from "src/features/participation";
import {isParticipationViewAvailable} from "src/features/session";
import {Dialog} from "src/components/layout/modal/Dialog";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  paper: {
    padding: '6px 16px',
  },
});

export default function Dashboard(
  {
    loggedIn,

    loaded,
    willParticipate,
    wontParticipate,
    hasPaid,
    showDocuments,

    updatingParticipation,
    participationUpdateError,

    price_net,
    vat,
    price_gross,
    organization,

    takePart,
    doNotTakePart,
    revertDecision,
  },
) {
  const classes = useStyles();

  const allowParticipationView = useSelector(isParticipationViewAvailable);

  useEffect(() => {
    if (!loggedIn) {
      navigate('/');
    }
  }, [loggedIn]);

  useEffect(() => {
    if (!allowParticipationView) {
      navigate('/dashboard');
    }
  }, [allowParticipationView]);

  const {
    has_credentials: hasCredentials,
  } = useSelector(getProfile);

  const [closedDashboardInfo, setClosedDashboardInfo] = useState(false);

  return (
    <WelcomeDashboardLayout
      titlePrefix="Teilnahme"
      hideGotoDashboard
      pictureBox={(
        <Box mx={4} my={13}>
          <Fade appear in>
            <Paper elevation={3} className={classes.paper}>
              <Box mx={4} my={4}>
                <Typography variant="h5" component="h2" gutterBottom>
                  Projektstatus &amp; Zeitplan
                </Typography>
                <Typography gutterBottom>
                  Die Entwicklung der Plattform sendemeldung.de erfolgt auf
                  Veranlassung von und in enger Abstimmung mit den Verbänden APR und VAUNET.
                  Nachfolgend finden Sie den aktuellen Zeitplan.
                </Typography>
              </Box>

              <ProjectTimeline showFuture={!wontParticipate}/>

              {/*{wontParticipate || true ? (*/}
              {/*  <Collapse in={wontParticipate}>*/}
              {/*    <Box mx={4} mb={4}>*/}
              {/*      <Alert variant="filled" severity="warning">*/}
              {/*        <AlertTitle>Hinweis</AlertTitle>*/}
              {/*        Das Projekt kann nur finanziert und umgesetzt werden, wenn sich der ganz überwiegende */}
              {/*        Anteil der Verbandsmitglieder an der Umlage beteiligt.*/}
              {/*      </Alert>*/}
              {/*    </Box>*/}
              {/*  </Collapse>*/}
              {/*) : null}*/}
            </Paper>
          </Fade>
        </Box>
      )}
    >
      <Box mt={2}>
        <Typography variant="h5" component="h2" gutterBottom>
          Ihre Kontaktdaten
        </Typography>
        <ContactDetails/>
      </Box>

      <Box mt={6}>
        <Typography variant="h5" component="h2" gutterBottom>
          Ihre Teilnahme
        </Typography>

        <Participation
          loaded={loaded}
          willParticipate={willParticipate}
          wontParticipate={wontParticipate}
          hasPaid={hasPaid}
          organization={organization}

          isSubmitting={updatingParticipation}
          updateError={participationUpdateError}

          takePart={takePart}
          doNotTakePart={doNotTakePart}
          revertDecision={revertDecision}
        />
      </Box>

      {showDocuments ? (
        <Box mt={6}>
          <Typography variant="h5" component="h2" gutterBottom>
            Ihre Dokumente
          </Typography>
          <Downloads/>
          <Collapse in={willParticipate && !hasPaid && price_gross > 0}>
            <Box mt={2}>
              <Alert variant="outlined" severity="info">
                <AlertTitle>
                  Ihr Anteil an der Projektfinanzierung beträgt <Price value={price_net}/> zzgl. <Price
                  value={vat}/> USt. &ndash; gesamt: <Price value={price_gross}/>.
                </AlertTitle>
                Mit deren Ausgleich kommt der Vertrag mit unserer Gesellschaft zustande, wie es im Schreiben,
                dem Sie den Zugangslink entnommen haben, beschrieben ist.
                Die steuerlich relevante Rechnung steht Ihnen dann hier zum Download bereit.
              </Alert>
            </Box>
          </Collapse>
        </Box>
      ) : null}

      {hasCredentials && !closedDashboardInfo ? (
        <>
          <Dialog
            aria-labelledby='info-dialog-title'
            open={true}
            onClose={() => setClosedDashboardInfo(true)}
            fullScreen={false}
          >
            <DialogTitle id='info-dialog-title'>Neues Dashboard verfügbar</DialogTitle>
            <DialogContent style={{whiteSpace: 'pre-wrap'}}>
              <p>
                Ab sofort finden Sie alle Informationen zum Projekt in unserem neuen Dashboard, in dem Sie auch die
                Verarbeitung Ihrer Sendemeldungen testen können.
              </p>
              <p>
                Wir leiten Sie nun zum neuen Dashboard weiter.
              </p>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setClosedDashboardInfo(true)}>auf dieser Seite bleiben</Button>
              <Button onClick={() => {
                navigate("/dashboard/");
                setClosedDashboardInfo(true);
              }} color={'primary'} autoFocus>Zum neuen Dashboard wechseln</Button>
            </DialogActions>
          </Dialog>
        </>
      ) : null}
    </WelcomeDashboardLayout>
  );
}
