import React from "react"
import {WelcomeDashboardLayout} from "src/components/layout";
import {Box, Button, Collapse, Divider, NoSsr, Typography} from "@material-ui/core";
import {Link} from "gatsby-theme-material-ui";
import {LoginForm, OldLoginForm} from "src/components/welcome";
import {navigate} from "gatsby";
import {useDispatch, useSelector} from "react-redux";
import {getOrganizations} from "src/features/dashboard";
import {hasProfileData} from "src/features/participation";
import {isTokenLoginFormPreferred, preferTokenLoginForm} from "src/features/ui/preferences";

export default function Welcome({loggedIn}) {
  const hasProfile = useSelector(hasProfileData);
  const hasOrganizations = useSelector(getOrganizations)?.length > 0;

  const dispatch = useDispatch();
  const showTokenForm = useSelector(isTokenLoginFormPreferred);

  return (
    <WelcomeDashboardLayout>
      <Box mt={4}>
        <Typography variant="h5" component="h1" gutterBottom>
          Liebes Sendeunternehmen,
        </Typography>
        <Typography gutterBottom>
          Sendemeldung.de unterstützt Sie bei der Übermittlung Ihrer
          Sendemeldungen an die Verwertungsgesellschaften GEMA und GVL.
        </Typography>
      </Box>
      <NoSsr>
        <Collapse in={!loggedIn} appear>
          <Box mt={4} mx={-4}>
            <Divider/>
            <Box py={4} mx={4}>
              <Typography variant="h6" component="h2" gutterBottom>
                Login
              </Typography>
              {/*<Typography gutterBottom>*/}
              {/*  Loggen Sie sich ein, um mehr zu erfahren.*/}
              {/*</Typography>*/}
              <Collapse in={!showTokenForm}>
                <LoginForm visible={!showTokenForm}/>
                <Box mt={2}>
                  <Button
                    color="primary"
                    variant="outlined"
                    type="submit"
                    style={{width: '100%'}}
                    size='large'
                    onClick={() => dispatch(preferTokenLoginForm(true))}
                  >
                    Haben Sie einen Zugangscode?
                  </Button>
                </Box>
              </Collapse>
              <Collapse in={showTokenForm}>
                <OldLoginForm visible={showTokenForm}/>
                <Box mt={2}>
                  <Button
                    color="primary"
                    variant="outlined"
                    type="submit"
                    style={{width: '100%'}}
                    size='large'
                    onClick={() => dispatch(preferTokenLoginForm(false))}
                  >
                    Benutzername / Passwort verwenden
                  </Button>
                </Box>
              </Collapse>
            </Box>
            <Divider/>
          </Box>
        </Collapse>
      </NoSsr>
      <Collapse in={loggedIn && hasOrganizations}>
        <Box mt={4} mx={-4}>
          <Divider/>
          <Box py={4} mx={4}>
            <Typography variant="h6" component="h2" gutterBottom>
              Ihre Sendemeldungen
            </Typography>
            <Typography gutterBottom>
              Hier können Sie Ihre Sendemeldungen verwalten.
            </Typography>
            <Box mt={2}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => navigate('/dashboard/')}
                style={{width: '100%'}}
              >
                zum Dashboard
              </Button>
            </Box>
          </Box>
          <Divider/>
        </Box>
      </Collapse>
      <Collapse in={loggedIn && !hasOrganizations && hasProfile}>
        <Box mt={4} mx={-4}>
          <Divider/>
          <Box py={4} mx={4}>
            <Typography variant="h6" component="h2" gutterBottom>
              Ihre Teilnahme
            </Typography>
            <Typography gutterBottom>
              Hier können Sie Ihre Teilnahme verwalten.
            </Typography>
            <Box mt={2}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => navigate('/teilnahme/dashboard/')}
                style={{width: '100%'}}
              >
                Informationen zu Ihrer Teilnahme
              </Button>
            </Box>
          </Box>
          <Divider/>
        </Box>
      </Collapse>
      <Box my={4}>
        <Typography variant="h6" component="h1" gutterBottom>
          Was ist Sendemeldung.de?
        </Typography>
        <Typography gutterBottom>
          Sendemeldung.de ermöglicht es Ihnen, Ihre Sendemeldungen aus dem bislang
          verwendeten <strong>GEMAGVL4</strong>-Format ohne Softwareinstallation und ohne Anpassung Ihrer Sendesysteme
          ins neue <strong>GEMAGVL-XML</strong>-Format zu überführen.
        </Typography>
        <Typography gutterBottom>
          Ihre Sendemeldungen laden Sie dazu einfach im bestehenden Format hoch.
          Sollten Ihre Meldungen unvollständig oder fehlerhaft sein, wird Sie das System unterstützen, Mängel zu
          identifizieren und etwaige Lücken zu schließen, um eine möglichst reibungslose Abwicklung durch die
          Verwertungsgesellschaften zu erlauben. Hierzu arbeiten wir eng mit den Verwertungsgesellschaften sowie
          den Verbänden
          {' '}
          <Link color="inherit" href="https://www.privatfunk.de/" target="_blank">APR</Link>
          {' '}und{' '}
          <Link color="inherit" href="https://www.vau.net/" target="_blank">VAUNET</Link>
          {' '}
          zusammen.
        </Typography>
      </Box>
      <Box my={4}>
        <Typography variant="h6" component="h1" gutterBottom>
          Wann?
        </Typography>
        <Typography gutterBottom>
          Die Mitglieder von APR und VAUNET haben die Finanzierung der Webplattform sichergestellt und haben bereits
          Zugriff auf eine Entwicklervorschau.
          Im August wird die Überführung der Sendemeldungen ins neue Format mit den Verwertungsgesellschaften
          ausführlich getestet.
          Ab September 2021 wird es möglich sein, offizielle GEMAGVL-XML-Sendemeldungen (für alle Ausstrahlungen ab Juli
          2021) zu generieren.
          Ende 2021 steht auch eine zentrale Musikdatenbank zur Verfügung, anhand derer fehlende Metadaten zu
          Sendemeldungen teilautomatisch ergänzt werden können.
        </Typography>
      </Box>
      <Box my={4}>
        <Typography variant="h6" component="h1" gutterBottom>
          Wer?
        </Typography>
        <Typography gutterBottom>
          Als Mitglied eines der Verbände
          {' '}
          <Link color="inherit" href="https://www.privatfunk.de/" target="_blank">APR</Link>
          {' '}oder{' '}
          <Link color="inherit" href="https://www.vau.net/" target="_blank">VAUNET</Link>
          {' '}
          haben Sie bereits einen Zugangscode von uns erhalten.
        </Typography>
        <Typography gutterBottom>
          Selbstverständlich wird unsere Plattform auch Nicht-Verbandsmitgliedern offen stehen.
          Kontaktieren Sie uns gerne unter
          {' '}
          <Link to="mailto:kontakt@sendemeldung.de" color="primary">kontakt@sendemeldung.de</Link>.
        </Typography>
      </Box>
      <Box my={4}>
        <Typography gutterBottom>
          Wir halten Sie auf dem Laufenden!
        </Typography>
        <Typography gutterBottom>
          Ihr Team der SOLE Software GmbH
        </Typography>
      </Box>
    </WelcomeDashboardLayout>
  );
}
